<div class="d-flex">
  <div>
    <app-sidebar></app-sidebar>
  </div>
  <div>



        <div class="mt-5 mb-3 row justify-content-end">
          <input type="text" class="form-control" id="exampleFormControlFile1" placeholder="Caută" style="width: 250px;" [(ngModel)] = "search" (input) = "setFilteredItems()">
        </div>

    <table class="table table-striped table-bordered table-borderless mx-5" *ngIf = "!loader">
        <thead>
          <tr>
            <th scope="col">NUME</th>
            <th scope="col">ORAȘ</th>
            <th scope="col">ADRESA</th>
            <th scope="col">TELEFON</th>
            <th scope="col">EMAIL</th>
            <th scope="col">EDIT</th>
          </tr>
        </thead>
        <tbody *ngIf = "search == ''">
          <tr *ngFor = "let user of users">
            <td>{{user?.firstName }} {{user?.lastName}} </td>
            <td>{{user?.city }}</td>
            <td>{{user?.address}}</td>
            <td>{{user?.phone}}</td>
            <td>{{user?.email}}</td>
            <td> <img src = "assets/edit.png" (click) = "gotodetails(user.userId)"></td>
          </tr>
        </tbody>
        <tbody *ngIf = "search != ''">
          <tr *ngFor = "let user of filterResult">
            <td>{{user?.firstName }} {{user?.lastName}} </td>
            <td>{{user?.city }}</td>
            <td>{{user?.address}}</td>
            <td>{{user?.phone}}</td>
            <td>{{user?.email}}</td>
            <td> <img src = "assets/edit.png" (click) = "gotodetails(user.userId)"></td>
          </tr>
        </tbody>
      </table>
    <div class="mt-5 ms-5">
      <sa-spinner-circular [enabled]="loader" size = "300px" class="w-75" color = "#C41230"></sa-spinner-circular>
    </div>
  </div>
</div>
