<div class="d-flex">
  <div>
    <app-sidebar></app-sidebar>
  </div>
  <div>
    <div class="row justify-content-center mt-5">
      <div class="col-auto">
        <div class="me-5" style="margin-left:50px;">
            <img src="{{picture}}" class="rounded-circle" width="128px" height="128px">
        </div>
      </div>
      <div class="col-auto">

        <div>
              <span><b>{{user?.firstName}} {{user?.lastName}}</b></span> <br>
              <span><b>{{user?.email}}</b></span> <br>
              <span>Telefon:  {{user?.phone}}</span> <br>
              <span>Adresa:  {{user?.address}}</span> <br>
              <span>Localitate: {{user?.city}}</span> <br>
              <span *ngIf = "user?.address2">Second adresa : {{user?.address2}}</span> <br>
              <span *ngIf = "user?.address3">Third adresa: {{user?.address3}}</span> <br>
              <span *ngIf = "user?.discountCode">Cod voucher: <strong> {{user?.discountCode}} </strong></span> <br>
              <!-- <span>Voucher Status:{{user?.voucherStatus}}</span> -->
              <!-- <span>{{user?.address}}</span> <br> -->


         </div>
        <div class="d-flex mt-5">
          <button class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal" (click) = "addNew()">Adaugă echipament</button>
          <button class="btn btn-warning ms-5" data-bs-toggle="modal" data-bs-target="#exampleModal1">Trimite notificare</button>
          <button class="btn btn-success ms-5" (click) = "sendDiscount()">Trimite voucher</button>

        </div>
        <sa-spinner-circular [enabled]="emailLoader" size = "150px" color = "#C41230" style="margin-left: 35%;"></sa-spinner-circular>


      </div>
    </div>
    <sa-spinner-circular [enabled]="loader" size = "150px" color = "#C41230" style="margin-left: 35%;"></sa-spinner-circular>
    <sa-spinner-circular [enabled]="notificationLoader" size = "150px" color = "#C41230" style="margin-left: 35%;"></sa-spinner-circular>

    <div class="row my-5 mx-5">
        <div class="col">
           <h1 class="text-danger" style="margin-left:1em;">Echipamente</h1>
           <div class="card shadow px-5 py-2 mb-3" *ngFor = "let order of orders;let i = index">
             <p>Tip echipament: <span class="text-danger">{{order.data.equipmentType}} </span></p>
             <p>Producător: <span class="text-danger">{{order.data.equipmentBrand}} </span></p>
             <p>Model: <span class="text-danger">{{order.data.equipmentModel}} </span></p>
             <p>Data achiziție: <span class="text-danger">{{order.data.purchaseDate | date : "dd-MM-yyyy"}} </span></p>
             <p *ngIf = "order.data.commercialWarranty">Garanție comercială: <span class="text-danger">{{order.data.commercialWarrantyDate | date : "dd-MM-yyyy" }} </span></p>
             <p *ngIf = "order.data.extendedWarranty">Garanție extinsă: <span class="text-danger">{{order.data.extendedWarrantyDate | date : "dd-MM-yyyy"}} </span></p>
             <p *ngIf = "order.data.contractType">Contract Mentenanță: <span class="text-danger">{{order.data.contractType}} </span></p>
             <p *ngIf = "order.data.contractNumber">Numar contract: <span class="text-danger">{{order.data.contractNumber}} </span></p>
             <p *ngIf = "order.data.paymentPeriod">Interval de plată: <span class="text-danger">{{order.data.paymentPeriod}} </span></p>
             <p>Serial Number: <span class="text-danger">{{order.data.serialNumber}}</span></p>
             <p>Address: <span class="text-danger">{{order.data.address}}</span></p>
             
             <p *ngIf = "order.data.revisionDate">Următoarea revizie: <span class="text-danger">{{order.data.revisionDate | date : "dd-MM-yyyy"}}</span></p>
             <p *ngIf = "order.plan != null"> <span class="text-success">Abonament: {{order.plan}}</span> </p>
             <p *ngIf = "order.status != null"> <span class="text-success">Status abonament: {{order.status}}</span></p>
             <p *ngIf = "order.date != null"> <span class="text-success">Data reinoire: {{order.date}}</span>            
                <button class="btn btn-danger" style="width: 210px;margin-left: 30px;" (click) = "deleteSub(order?.key,order.data?.subscription_id)">Delete Subscription</button>
             </p>
             <sa-spinner-circular [enabled]="deleteSubLoader" size = "150px" color = "#C41230" style="margin-left: 35%;"></sa-spinner-circular>

             <hr>
             <div class="d-flex">
              <button class="btn btn-dark me-4" data-bs-toggle="modal" data-bs-target="#exampleModal2" style="width: 33%;" (click) = "selectedItem(order.key,order.data)">Editează echipament</button>
              <button class="btn btn-danger me-4" style="width: 33%;" (click) = onDelete(order.key)>Șterge echipament</button>
              <button class="btn btn-outline-danger me-4" style="width: 33%;" data-bs-toggle="modal" data-bs-target="#exampleModal3" (click) = "addService(order.key,order.data)">Adaugă service</button>
             </div>
             <sa-spinner-circular [enabled]="deleteEquipLoader" size = "150px" color = "#C41230" style="margin-left: 35%;"></sa-spinner-circular>

             <!----->
             <!-- <div class="accordion my-3" id="accordionExample" style="background-color: white;">
              <div class="accordion-item"> -->
                <h2 class="accordion-header my-3" id="headingOne">
                  <button class="accordion-button btn btn-danger" type="button" (click) = "showService(order.key)">
                      Arată intervențiile service
                  </button>
                </h2>
                <!-- <div> -->
                  <div *ngIf = "showKey == order.key">
                   <div class="mt-2">
                   <h5>Services:</h5>
                   <div *ngFor = "let ap of appointments">
                        <div *ngIf = "ap?.data?.orderId == order.key">
                          <p>Intervenție: <span class="text-danger">{{ap?.data?.service}}</span></p>
                          <p>Data intervenției: <span class="text-danger">{{ap?.data?.dateIntervention | date : "dd-MM-YYYY"}}</span></p>
                          <p>Intervalul intervenției: <span class="text-danger">{{ap?.data?.timingIntervention}}</span></p>
                          <p>Status: <span class="text-danger">{{ap?.data?.status}}</span></p>
                          <p>Nota internă: <span class="text-danger">{{ap?.data?.comments}}</span></p>
                          <p *ngIf = "ap?.data?.text">Problem Description: <span class="text-danger">{{ap?.data?.text}}</span></p>

                          <div class="d-flex" style="margin-top: 40px;">
                             <button class="btn btn-outline-danger me-4" data-bs-toggle="modal" data-bs-target="#exampleModal4" (click) = "deleteService(ap?.id)">Șterge intervenția</button>
                             <button class="btn btn-outline-dark me-4" data-bs-toggle="modal" data-bs-target="#exampleModal5" (click) = "editService(ap?.id,ap?.data)">Editează intervenția</button>
                          </div>





                         <hr>
                        </div>
                    </div>
                   </div>
                  </div>
                  <!-- </div>  -->
                 <!-- </div>
                </div> -->

                         <!----->


           </div>
        </div>
    </div>
    <sa-spinner-circular [enabled]="loader" size = "150px" color = "#C41230" style="margin: 0 auto;display: block;"></sa-spinner-circular>

  </div>
</div>

<div class="modal fade exampleModal-modal-lg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Equipment</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup] = "addNewEquipment" (ngSubmit) = "onSubmit()">
          <div class="mb-2">
            <label for="recipient-name" class="col-form-label">Equipment type:</label>
            <select class="form-select" aria-label="Default select example" formControlName = "equipmentType" (change) = "onSelectType('new')">
                <option *ngFor = "let type of types" [value] = type>{{type}}</option>
            </select>
          </div>
          <div class="mb-2">
            <label for="recipient-name" class="col-form-label">Equipment Brand:</label>
            <select class="form-select" aria-label="Default select example" formControlName = "equipmentBrand" (change) = "onSelectBrand('new')">
                <option *ngFor = "let brand of brands" [value] = "brand.name">{{brand.name}}</option>
            </select>
          </div>
          <div class="mb-2">
            <label for="recipient-name" class="col-form-label">Equipment Model:</label>
            <select class="form-select" aria-label="Default select example" formControlName = "equipmentModel">
                <option *ngFor = "let model of models;let i = index" [value] = "i">{{model.name}}</option>
            </select>
          </div>
          <div class="mb-2">
            <label for="message-text" class="col-form-label">Purchase date</label>
            <input type="date" class="form-control" id="message-text" formControlName = "purchaseDate" min = "2017-04-01" [max] = "max">
          </div>
          <div class="mb-2">
            <label for="message-text" class="col-form-label me-3">Commercial warranty</label> <br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio1" value=2 formControlName = "commercialWarranty">
              <label class="form-check-label" for="inlineRadio1">2 years</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=3 formControlName = "commercialWarranty">
              <label class="form-check-label" for="inlineRadio2">3 years</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=5 formControlName = "commercialWarranty">
              <label class="form-check-label" for="inlineRadio2">5 years</label>
            </div><br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineRadio2" value="true" formControlName = "extraWarranty">
              <label class="form-check-label" for="inlineRadio2">Extra promo warranty 2 years</label>
            </div>
          </div>
          <div class="mb-2">
            <label for="message-text" class="col-form-label me-3">Extended warranty</label> <br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio1" value=1 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio1">1 year</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=2 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio2">2 years</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio1" value=3 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio1">3 year</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=4 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio2">4 years</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=5 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio2">5 years</label>
            </div>
          </div>
          <div class="mb-2">
            <label for="message-text" class="col-form-label">SERIAL Number:</label>
            <input type="text" class="form-control" id="message-text" formControlName = "serialNumber">
          </div>
          <div class="mb-2">
            <label for="recipient-name" class="col-form-label">Address:</label>
            <select class="form-select" aria-label="Default select example" formControlName = "address">
                <option value = "{{user?.address}}">{{user?.address}}</option>
                <option value = "{{user?.address2}}">{{user?.address2}}</option>
                <option value = "{{user?.address3}}">{{user?.address3}}</option>
            </select>
          </div>
          <!-- <div class="mb-2">
            <label for="message-text" class="col-form-label">Next Revision date:</label>
            <input type="date" class="form-control" id="message-text" formControlName = "revisionDate" [min] = "max">
          </div> -->
          <div class="mb-2" *ngIf = "show">
            <label for="message-text" class="col-form-label">Contract Number:</label>
            <input type="text" class="form-control" id="message-text" formControlName = "contractNumber">
          </div>
          <div class="mb-2" *ngIf = "show">
            <label for="recipient-name" class="col-form-label">Contract type</label>
            <select class="form-select" aria-label="Default select example" formControlName = "contractType">
                <option selected value = "cps start">CPS START</option>
                <option value="cps standard">CPS PLUS</option>
                <option value="cps plus">CPS PREMIUM</option>
                <option value="cps smart">CPS SMART</option>
            </select>
          </div>
          <div class="mb-2" *ngIf = "show">
            <label for="message-text" class="col-form-label me-3">Contract payment period</label>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="inlineRadio1" value="monthly" formControlName = "paymentPeriod">
                <label class="form-check-label" for="inlineRadio1">Monthly</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="inlineRadio2" value="yearly" formControlName = "paymentPeriod">
                <label class="form-check-label" for="inlineRadio2">Yearly</label>
              </div>
          </div>
          <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal" [disabled] = "!addNewEquipment.valid">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade exampleModal-modal-lg" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Equipment</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form [formGroup] = "editNewEquipment" (ngSubmit) = "onUpdate()">
          <div class="mb-2">
            <label for="recipient-name" class="col-form-label">Equipment type:</label>
            <select class="form-select" aria-label="Default select example" formControlName = "equipmentType" (change) = "onSelectType('edit')">
                <option *ngFor = "let type of types" [value] = type>{{type}}</option>
            </select>
          </div>
          <div class="mb-2">
            <label for="recipient-name" class="col-form-label">Equipment Brand:</label>
            <select class="form-select" aria-label="Default select example" formControlName = "equipmentBrand" (change) = "onSelectBrand('edit')">
                <option *ngFor = "let brand of brands" [value] = "brand?.name">{{brand?.name}}</option>
            </select>
          </div>
          <div class="mb-2">
            <label for="recipient-name" class="col-form-label">Equipment Model:</label>
            <select class="form-select" formControlName = "equipmentModel">
                <option *ngFor = "let model of models;let i = index" [value] = "model?.name">{{model?.name}}</option>
            </select>
          </div>
          <div class="mb-2">
            <label for="message-text" class="col-form-label">Purchase date</label>
            <input type="date" class="form-control" id="message-text" formControlName = "purchaseDate" min = "2017-04-01" [max] = "max">
          </div>
          <div class="mb-2">
            <label for="message-text" class="col-form-label me-3">Commercial warranty</label> <br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio1" value=2 formControlName = "commercialWarranty">
              <label class="form-check-label" for="inlineRadio1">2 years</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=3 formControlName = "commercialWarranty">
              <label class="form-check-label" for="inlineRadio2">3 years</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=5 formControlName = "commercialWarranty">
              <label class="form-check-label" for="inlineRadio2">5 years</label>
            </div>
            <br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineRadio2" value="true" formControlName = "extraWarranty">
              <label class="form-check-label" for="inlineRadio2">Extra promo warranty 2 years</label>
            </div>
          </div>
          <div class="mb-2">
            <label for="message-text" class="col-form-label me-3">Extended warranty</label> <br>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio1" value=1 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio1">1 year</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=2 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio2">2 years</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio1" value=3 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio1">3 year</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=4 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio2">4 years</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="inlineRadio2" value=5 formControlName = "extendedWarranty">
              <label class="form-check-label" for="inlineRadio2">5 years</label>
            </div>
          </div>
          <div class="mb-2">
            <label for="message-text" class="col-form-label">SERIAL Number:</label>
            <input type="text" class="form-control" id="message-text" formControlName = "serialNumber">
          </div>
          <!-- <div class="mb-2">
            <label for="message-text" class="col-form-label">Next Revision date:</label>
            <input type="date" class="form-control" id="message-text" formControlName = "revisionDate">
          </div> -->
          <div class="mb-2">
            <label for="recipient-name" class="col-form-label">Address:</label>
            <select class="form-select" aria-label="Default select example" formControlName = "address">
                <option value = "{{user?.address}}">{{user?.address}}</option>
                <option value = "{{user?.address2}}">{{user?.address2}}</option>
                <option value = "{{user?.address3}}">{{user?.address3}}</option>
            </select>
          </div>
          <div class="mb-2" *ngIf = "show">
            <label for="message-text" class="col-form-label">Contract Number:</label>
            <input type="text" class="form-control" id="message-text" formControlName = "contractNumber">
          </div>
          <div class="mb-2" *ngIf = "show">
            <label for="recipient-name" class="col-form-label">Contract type</label>
            <select class="form-select" aria-label="Default select example" formControlName = "contractType">
                <option selected value = "cps start">CPS START</option>
                <option value="cps standard">CPS PLUS</option>
                <option value="cps plus">CPS Premium</option>
            </select>
          </div>
          <div class="mb-2" *ngIf = "show">
            <label for="message-text" class="col-form-label me-3">Contract payment period</label>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="inlineRadio1" value="monthly" formControlName = "paymentPeriod">
                <label class="form-check-label" for="inlineRadio1">Monthly</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="inlineRadio2" value="yearly" formControlName = "paymentPeriod">
                <label class="form-check-label" for="inlineRadio2">Yearly</label>
              </div>
          </div>
          <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal">Update</button>
        </form>
      </div>
    </div>
  </div>
</div>



<div class="modal fade exampleModal-modal-lg" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Appointment</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <form [formGroup] = "notification" (ngSubmit) = "onNotification()">
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Appointment Title</label>
          <input type="text" class="form-control" id="message-text" formControlName = "title">
        </div>
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Appointment message</label>
          <input type="text" class="form-control" id="message-text" formControlName = "message">
        </div>
         <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal">Send</button>
       </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade exampleModal-modal-lg" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Service</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <form [formGroup] = "addServiceForm" (ngSubmit) = "submitServiceForm()">
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Service Date</label>
          <input type="date" class="form-control" id="message-text"  formControlName = "dateIntervention">
        </div>
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Service Intervention</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio1" value="PIF" formControlName = "service" (click) = "toggleRadio(false)">
          <label class="form-check-label" for="inlineRadio1">PIF</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio2" value="VTP" formControlName = "service" (click) = "toggleRadio(false)">
          <label class="form-check-label" for="inlineRadio2">VTP</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio2" value="Constatare" formControlName = "service" (click) = "toggleRadio(false)">
          <label class="form-check-label" for="inlineRadio2">Constatare</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio2" value="Other" formControlName = "service" (click) = "toggleRadio(true)">
          <label class="form-check-label" for="inlineRadio2">Other</label>
        </div>
        </div>
        <div class="mb-2" *ngIf = "other">
          <label for="message-text" class="col-form-label">Enter Service here</label>
           <input type="text" class="form-control" id="message-text" formControlName = "service">
        </div>
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Service Time</label>
          <select class="form-select" aria-label="Default select example" formControlName = "timingIntervention">
            <option selected value="09:00 - 15:00">09:00 - 15:00</option>
            <option value="15:00 - 17:00">15:00 - 17:00</option>
            <option value="After 5pm">After 5pm</option>
          </select>
       </div>
        <div class="mb-2">
          <label for="message-text" class="col-form-label me-3">Next revision date</label> <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="inlineRadio1" value=1 formControlName = "revisionDate">
            <label class="form-check-label" for="inlineRadio1">1 year</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="inlineRadio2" value=2 formControlName = "revisionDate">
            <label class="form-check-label" for="inlineRadio2">2 years</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="inlineRadio2" value=null formControlName = "revisionDate">
            <label class="form-check-label" for="inlineRadio2">none</label>
          </div>
        </div>
        <div class="mb-2">
          <label for="recipient-name" class="col-form-label">Comment:</label>
          <textarea  class="form-control" id="message-text" formControlName = "comments"></textarea>
        </div>
         <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal" [disabled] = "!addServiceForm.valid">Send</button>
       </form>
      </div>
    </div>
  </div>
</div>


<div class="modal fade exampleModal-modal-lg" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Delete Service</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <!-- <form [formGroup] = "addServiceForm" (ngSubmit) = "submitServiceForm()">
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Service Date</label>
          <input type="date" class="form-control" id="message-text"  formControlName = "dateIntervention">
        </div>
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Service Intervention</label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio1" value="PIF" formControlName = "service" (click) = "toggleRadio(false)">
          <label class="form-check-label" for="inlineRadio1">PIF</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio2" value="VTP" formControlName = "service" (click) = "toggleRadio(false)">
          <label class="form-check-label" for="inlineRadio2">VTP</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio2" value="Other" formControlName = "service" (click) = "toggleRadio(true)">
          <label class="form-check-label" for="inlineRadio2">Other</label>
        </div>
        </div>
        <div class="mb-2" *ngIf = "other">
          <label for="message-text" class="col-form-label">Enter Service here</label>
           <input type="text" class="form-control" id="message-text" formControlName = "service">
        </div>
        <div class="mb-2">
          <label for="recipient-name" class="col-form-label">Comment:</label>
          <textarea  class="form-control" id="message-text" formControlName = "comments"></textarea>
        </div>
         <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal" [disabled] = "!addServiceForm.valid">Send</button>
       </form> -->
       <p>Are you sure! You want to delete?</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark"  data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger"  data-bs-dismiss="modal" (click) = "onDeleteService()">Yes</button>
      </div>
    </div>
  </div>
</div>


<div class="modal fade exampleModal-modal-lg" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Service</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <form [formGroup] = "editServiceForm" (ngSubmit) = "onEditService()">
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Service Date</label>
          <input type="date" class="form-control" id="message-text"  formControlName = "dateIntervention" >
        </div>
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Service Intervention</label>
          <input type="text" class="form-control" id="message-text"  formControlName = "service">
        </div>
        <div class="mb-2">
          <label for="message-text" class="col-form-label">Service Time</label>
          <select class="form-select" aria-label="Default select example" formControlName = "timingIntervention">
            <option selected value="09:00 - 15:00">09:00 - 15:00</option>
            <option value="15:00 - 17:00">15:00 - 17:00</option>
            <option value="After 5pm">After 5pm</option>
          </select>
       </div>
       <div class="mb-2">
        <label for="message-text" class="col-form-label me-3">Next revision date</label> <br>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio1" value = 1 formControlName = "revisionDate">
          <label class="form-check-label" for="inlineRadio1">1 year</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio2" value = 2 formControlName = "revisionDate">
          <label class="form-check-label" for="inlineRadio2">2 years</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="inlineRadio2" value = null formControlName = "revisionDate">
          <label class="form-check-label" for="inlineRadio2">none</label>
        </div>
      </div>
        <div class="mb-2">
          <label for="recipient-name" class="col-form-label">Comment:</label>
          <textarea  class="form-control" id="message-text" formControlName = "comments"></textarea>
        </div>
         <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal" [disabled] = "!editServiceForm.valid">Send</button>
       </form>
      </div>
    </div>
  </div>
</div>
