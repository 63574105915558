import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private utilityService : UtilityService) { }

  ngOnInit(): void {
  }
  onLogout()
   {
    this.utilityService.logoutUser() 
   }
}
