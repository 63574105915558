import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private fs : AngularFirestore,
              private toastr: ToastrService,
              private http : HttpClient,
              private auth : AngularFireAuth,
              private router : Router
     ) { }
  
  loginUser(value)
   {
    return this.auth.signInWithEmailAndPassword(value.email,value.password)
   }
  getProfile(id)
   {
    return this.fs.collection('admin').doc(id).valueChanges()
   }    
  getEquipments()
   {
     return this.fs.collection('equipments').valueChanges()
   }
  getUsers()
   {
     return this.fs.collection('users').valueChanges();
   }
  getSingleUser(id)
   {
    return this.fs.collection('users').doc(id).valueChanges();
   }
   addEquipment(data,commercial,extendedWarranty,userId,image)
    {
     let id = this.fs.createId()
     return this.fs.collection('orders').doc(id).set({
          'equipmentType' : data.equipmentType,
          'equipmentBrand' : data.equipmentBrand,
          'equipmentModel' : data.equipmentModel,
          'modelImage' : image,
          'purchaseDate' : data.purchaseDate,
          'commercialWarranty' : data.commercialWarranty,
          'extendedWarranty' : data.extendedWarranty,
          'contractNumber' : data.contractNumber,
          'contractType' : data.contractType,
          'paymentPeriod' : data.paymentPeriod,
          'serialNumber' : data.serialNumber,
          'commercialWarrantyDate' : commercial,
          'extendedWarrantyDate' : extendedWarranty,
          'userId' : userId,
          'address' : data.address
      })
    }
   getOrders(userId)
    {
      return this.fs.collection('orders', ref => ref.where('userId','==',userId));
    } 
    updateUser(userId)
     {
      return this.fs.collection('users').doc(userId).update({'status' : 'progress'})
     }  
     showSuccess(header,msg) {
      this.toastr.success(header,msg);
    }
    showError(header,msg)
     {
       this.toastr.error(header,msg)
     }
     getAppointments()
      {
        return this.fs.collection('appointments').snapshotChanges();
      }
      getPreviousAppointments()
       {
        return this.fs.collection('appointments',ref => ref.where('status',"!=",'pending')).snapshotChanges();

       }
      updateAppointment(data,userId)
       {
        return this.fs.collection('appointments').doc(userId).update({
          'timingIntervention' : data.timingIntervention,
          'dateIntervention' : data.dateIntervention,
          'status' : data.status,
          'comments' : data.comments || "",
          'text' : data.text || null
         })
      }
      getAppointmentByUser(userId)
      {
        return this.fs.collection('appointments',ref => ref.where('userId','==',userId)).snapshotChanges();
      } 
      sendNotification(notification,token)
       {
         let id = this.fs.createId()
         return this.fs.collection('notifications').doc(id).set({
            title : notification.title,
            message : notification.message,
            token : token
          })
       }
       sendNotificationtoAll(notification)
        {
          let id = this.fs.createId()
          return this.fs.collection('AllNotifications').doc(id).set({
             title : notification.title,
             message : notification.message,  
          })
        }
        getSubscriptionDetails(id)
        {
         return this.http.get(`https://us-central1-cpsapp2022.cloudfunctions.net/invoicepreview?subscriptionId=${id}`)
        } 
        updateEquipment(data,Key,commercial,extendedWarranty,image)
         {
          return this.fs.collection('orders').doc(Key).update({
            'equipmentType' : data.equipmentType,
            'equipmentBrand' : data.equipmentBrand,
            'equipmentModel' : data.equipmentModel,
            'purchaseDate' : data.purchaseDate,
            'commercialWarranty' : data.commercialWarranty,
            'extendedWarranty' : data.extendedWarranty,
            'contractNumber' : data.contractNumber,
            'contractType' : data.contractType,
            'paymentPeriod' : data.paymentPeriod,
            'serialNumber' : data.serialNumber,
            'commercialWarrantyDate' : commercial,
            'extendedWarrantyDate' : extendedWarranty,
            'modelImage' : image,
            'address' : data.address

         })
         }
        deleteEquipment(key)
         {
           return this.fs.collection('orders').doc(key).delete()
         }
         getOrderDetails(key)
          {
            return this.fs.collection('orders').doc(key).valueChanges();
          } 
          submitAppointment(value,orderKey,orderData)
           {
             let id = this.fs.createId();
             return this.fs.collection('appointments').doc(id).set({
               'status' : 'Complete',
               'dateIntervention' : value.dateIntervention,
                'priorityIntervention' : 'admin',
                'timingIntervention' : value.timingIntervention,
                'token' : null,
                userId : orderData.userId,
                orderId : orderKey,
                service : value.service,
                comments : value.comments,
                equipmentType : orderData.equipmentType,
                brand : orderData.equipmentBrand,
                model : orderData.equipmentModel,
                serialNumber : orderData.serialNumber,
                revisionDate : value.revisionDate
             })
           } 
           deleteServiceById(id)
            {
              return this.fs.collection('appointments').doc(id).delete()
            } 
         logoutUser()
          {
            this.auth.signOut().then(()=> {
              localStorage.clear();
              this.router.navigateByUrl('/login');
            })
          }

         editService(data,id)
          {
            return this.fs.collection('appointments').doc(id).update({
              'timingIntervention' : data.timingIntervention,
              'dateIntervention' : data.dateIntervention,
              'service' : data.service,
              'comments' : data.comments || "",
              'revisionDate': data.revisionDate 
             })
          }
          updateOrder(selectedOrderId,revisionDate,revisionTime)
           {
            return this.fs.collection('orders').doc(selectedOrderId).update({
              'revisionDate' : revisionDate,
              'revisionTime' : revisionTime
            });             
           } 
           addDiscount(id,code)
            {
              return this.fs.collection('users').doc(id).update({
                 'discountCode' : code,
                 'voucherStatus' : 'active'
              })
            }
            sendEmail(name,code,email)
            {
              let data = {
                name : name,
                code : code,
                email : email
              }
             return this.http.post('https://us-central1-cpsapp2022.cloudfunctions.net/sendEmail',data)
            }
            deleteSubscriptin(key,subId)
             {
               console.log("key",key)
               return this.http.get(`https://us-central1-cpsapp2022.cloudfunctions.net/deleteSubscriptions?subscriptionId=${subId}`)
            }
            updateSubscription(key)
             {
               return this.fs.collection('orders').doc(key).update({
                 'subscription_id' : null
               })
             }
}
