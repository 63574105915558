import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(private utilityService : UtilityService) { }

  ngOnInit(): void {
  }
  notification = new FormGroup({
    title : new FormControl(null,Validators.required),
    message : new FormControl(null,Validators.required),
   })
  onNotification()
  {
   this.utilityService.sendNotificationtoAll(this.notification.value).then(()=> {
    this.notification.reset();
    this.utilityService.showSuccess("Notification","Notification sent successfully"); 
  }).catch(err => {
    this.utilityService.showError("Notification","Something went wrong!!");
  }) 
  }  
}
