<app-navbar></app-navbar>
<table class="table table-borderless">
    <thead style="border-bottom:1px solid red">
      <tr>
        <th scope="col">NAME</th>
        <th scope="col">PHONE</th>
        <th scope="col">EMAIL</th>
        <th>Equipment</th>
        <th>Service</th>
        <th scope="col">Time intervention </th>
        <th scope="col">Date intervention </th>
        <th>Priority</th>
        <th>Status</th>
        <th>View </th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor = "let apt of appointments">
        <td>{{apt?.user?.firstName }} {{apt?.user?.lastName}} </td>
        <td>{{apt?.user?.phone}}</td>
        <td>{{apt?.user?.email}}</td>
        <td>{{apt?.data?.equipmentType}}</td>
        <td>{{apt?.data?.service}}</td>
        <td>{{apt?.data?.timingIntervention}}</td>
        <td>{{apt?.data?.dateIntervention}}</td>
        <td>{{apt?.data?.priorityIntervention}}</td>
        <td>{{apt?.data?.status}}</td>
        <td><u style="color:blue;cursor: pointer;" data-bs-toggle="modal" data-bs-target="#exampleModal2" (click) = "details(apt?.data?.orderId)">View</u></td>
        <td>
            <img src = "assets/edit.png" data-bs-toggle="modal" data-bs-target="#exampleModal" (click) = "selectedItem(apt?.key,apt?.data)">
        </td>
      </tr>
    
    </tbody>
  </table>
  <div class="modal fade exampleModal-modal-lg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Update Appointment</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form [formGroup] = "updateAppointment" (ngSubmit) = "onUpdate()">
            <div class="mb-2">
              <label for="recipient-name" class="col-form-label">Time intervention:</label>
              <select class="form-select" aria-label="Default select example" formControlName = "timingIntervention">
                  <option value = "09:00 - 15:00">09:00 - 15:00</option>
                  <option value = "15:00 - 17:00">15:00 - 17:00</option>
                  <option value = "After 5pm">After 5pm</option>
              </select>
            </div>
            <div class="mb-2">
              <label for="recipient-name" class="col-form-label">Date intervention:</label>
              <input type="date" class="form-control" id="message-text" formControlName = "dateIntervention" min="2022-03-28">
            </div> 
            <div class="mb-2">
              <label for="recipient-name" class="col-form-label">Status</label>
              <select class="form-select" aria-label="Default select example" formControlName = "status">
                <option value = "inProgress">In progress</option>
                <option value = "pending">Pending</option>
                <option value = "Complete">Complete</option>
            </select>
            </div>
            <div class="mb-2">
              <label for="recipient-name" class="col-form-label">Comment:</label>
              <textarea  class="form-control" id="message-text" formControlName = "comments"></textarea>
            </div>     
            <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal">Update</button>     
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade exampleModal-modal-lg" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Appointment Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>Type: <span class="text-danger">{{appointmentsDetails?.equipmentType}} </span></p>
          <p>Brand: <span class="text-danger">{{appointmentsDetails?.equipmentBrand}} </span></p>
          <p>Brand: <span class="text-danger">{{appointmentsDetails?.equipmentModel}} </span></p>
          <p>Purchase date : <span class="text-danger">{{appointmentsDetails?.purchaseDate}} </span></p>
          <p>Commercial Warranty: <span class="text-danger">{{appointmentsDetails?.commercialWarrantyDate | date : "yyyy-MM-dd" }} </span></p>
          <p>Extended Warranty: <span class="text-danger">{{appointmentsDetails?.extendedWarrantyDate | date : "yyyy-MM-dd"}} </span></p>
          <p>Contract Number: <span class="text-danger">{{appointmentsDetails?.contractNumber}} </span></p>
          <p>Contract Type: <span class="text-danger">{{appointmentsDetails?.contractType}} </span></p>
          <p>payment Period: <span class="text-danger">{{appointmentsDetails?.paymentPeriod}} </span></p>
          <p>Serial Number: <span class="text-danger">{{appointmentsDetails?.serialNumber}}</span></p>
          <!-- <p>revisionDate: <span class="text-danger">{{order.data.revisionDate}}</span></p> -->
          <p *ngIf = "appointmentsDetails?.plan != null"> <span class="text-danger">  Subscribe Plan: {{appointmentsDetails?.plan}}</span> </p>
          <p *ngIf = "appointmentsDetails?.status != null"> <span class="text-danger"> Subscription Status: {{appointmentsDetails?.status}}</span></p>
          <p *ngIf = "appointmentsDetails?.date != null"> <span class="text-danger">Renewal Date: {{appointmentsDetails?.date}}</span> </p>
        </div>
      </div>
    </div>
  </div>

