<div class="containerr">
  <div class="m-5">
      <img src="assets/logo.svg" width="220px">
  </div>
  <ul class="list-group">
    <li class="list-group-item bg rm-border" routerLink = "/customers" routerLinkActive = "active" aria-current="true">Clienți</li>
    <li class="list-group-item bg rm-border" routerLink = "/appointments" routerLinkActive = "active">Programări</li>
    <li class="list-group-item bg rm-border" routerLink = "/notifications">Notificări</li>
    <li class="list-group-item bg rm-border" (click) = "onLogout()">Logout</li>

  </ul>
</div>
