import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loader = false;
  constructor(private authService : AuthService,
              private router : Router,
              private utilityService : UtilityService) { }

  ngOnInit(): void {
  }
  login = new FormGroup({
    email : new FormControl(null,[Validators.required,Validators.email]),
    password : new FormControl(null,Validators.required,)
  })
  onSubmit()
   {
     this.loader = true;
     this.utilityService.loginUser(this.login.value).then(
       (res) => {
        this.loader = true
          this.utilityService.getProfile(res.user.uid).subscribe(x => {
            if(x == undefined)
             {
              this.loader = false;
               this.utilityService.showError("Login","User dosen't exist");
             }
             else {
              this.loader = true
              localStorage.setItem("admin",res.user.uid); 
              this.utilityService.showSuccess("Login","Login Successfully");
              this.router.navigateByUrl('/customers');
             }
          },(error) => {
            this.loader = false;
            this.utilityService.showError("Login",error.message);

          })   
       }).catch(err => {
           this.loader = false;
           this.utilityService.showError("Login",err.message);
           
       })

    // this.router.navigateByUrl('/customers');
   }
   get f()
    {
      return this.login?.controls;
    }
}
