import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  currentUser ;
  constructor(private router : Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   
      this.currentUser = localStorage.getItem("admin");
      if(this.currentUser == undefined || this.currentUser == null)
       {
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return false;
       }
       else {
        return true;
       }
  }
  
}
