<div class="d-flex">
  <div class="containerr">
    <div class="m-5">
        <img src="assets/logo.svg" width="220px">
    </div>
  </div>
  <div class="row justify-content-center align-content-center w-75" style="height: 100vh;">
    <div class="col-auto">
        <div class="card mt-5">
            <!-- <div class="mt-5">
                <img src="assets/logo.png" width="150px" style="margin: 0 auto;display: block;">
            </div> -->

            <form class="mx-5 mt-5" [formGroup] = "login" (ngSubmit) = "onSubmit()">
                <div class="mb-3">
                    <input type="email" class="form-control" placeholder="email address" formControlName = "email">
                </div>
                <div *ngIf = "f?.email?.errors && f?.email?.errors?.email">
                  <p class="text-danger">Please enter a valid email</p>
                </div>
                <div *ngIf="f.email.touched && f?.email?.errors && f?.email?.errors?.required">
                <p class="text-danger">Please enter a email</p>
               </div>
                <div class="mb-3"> 
                    <input type="password" class="form-control" placeholder="password" formControlName = "password">
                </div>
                <div *ngIf="f.password.touched && f?.password?.errors && f?.password?.errors?.required">
                    <p class="text-danger">Please enter a password</p>
                </div>
                <div class="d-grid gap-2 mb-5">
                 <button class="btn btn-danger" type="button" type="submit" [disabled] = "!login.valid">Login</button>
                 <sa-spinner-circular [enabled]="loader" size = "150px" color = "#C41230" style="margin: 0 auto;display: block;"></sa-spinner-circular>

                </div>
            </form>
        </div>
    </div>
 </div>
</div>
