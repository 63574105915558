<div class="d-flex">
  <div>
    <app-sidebar></app-sidebar>
  </div>
  <div>
        <button class="btn btn-danger m-5" style="width: 300px;" data-bs-toggle="modal" data-bs-target="#exampleModal1">Send Notification to All</button>
  </div>
  <div>
    <div class="modal fade exampleModal-modal-lg" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Notification</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
           <form [formGroup] = "notification" (ngSubmit) = "onNotification()">   
            <div class="mb-2">
              <label for="message-text" class="col-form-label">Notification Title</label>
              <input type="text" class="form-control" id="message-text" formControlName = "title">
            </div>
            <div class="mb-2">
              <label for="message-text" class="col-form-label">Notification message</label>
              <input type="text" class="form-control" id="message-text" formControlName = "message">
            </div>
             <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal">Send</button>   
           </form>
          </div>
        </div>
      </div>
    </div>