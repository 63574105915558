import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppointmentComponent } from './appointment/appointment.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AuthGuard } from './auth.guard';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { CustomersComponent } from './customers/customers.component';
import { LoginComponent } from './login/login.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PreviousAppointmentsComponent } from './previous-appointments/previous-appointments.component';
import { SidebarComponent } from './sidebar/sidebar.component';

const routes: Routes = [
 {
   path : '', redirectTo : 'customers', pathMatch : 'full'
 },
  {
    path : 'login', component : LoginComponent
  },
  {
    path : 'customers', component : CustomersComponent, canActivate :[AuthGuard]
  },
  {
    path : 'customers/details/:id',component : CustomerDetailsComponent, canActivate :[AuthGuard]
  },
  {
    path : 'appointments', component : AppointmentsComponent, canActivate :[AuthGuard]
  },
  {
    path : 'appointment',component : AppointmentComponent, canActivate :[AuthGuard]
  },
  {
    path : 'notifications', component : NotificationsComponent, canActivate :[AuthGuard]
  },
  {
    path : 'previous-appointments', component : PreviousAppointmentsComponent, canActivate :[AuthGuard]
  },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
