import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilityService } from '../utility.service';
import {map} from 'rxjs/operators'
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit,OnDestroy {
 appointments = [];
 subs : Subscription;
 subs2 : Subscription;
 selectedKey;
 selectedData;
 appointmentsDetails = [];
 status = "active";
 selectedOrderId;
 loader = false;
 loader2 = false;
 search = "";
 filterResult;
 problemDescription = null;
  constructor(private utilityService : UtilityService) { }

  ngOnInit(): void {
    this.appointments = [];
    this.getsAppointments(this.status);
  }   
  getsAppointments(status)
   {
    this.search = ""
     this.loader = true;
     this.status = status;
     this.appointments = [];
     this.subs2 =  this.utilityService.getAppointments().subscribe(x => {
        this.appointments = [];
         this.loader = false;
       x.forEach(el => {
        this.appointments = [];
          let data : any= el.payload.doc.data()
          let key = el.payload.doc.id;
          if(status == 'active')
           {
             if(data.status == 'pending')
              {               
                this.subs = this.utilityService.getSingleUser(data.userId).subscribe(x => {
                  let temp = this.appointments.filter(data => data.key == key)
                  if(temp.length == 0)
                   {
                    this.appointments.push({'key' : key,'data' : data,user : x})
                    this.appointments = this.appointments.sort((a,b) =>  new Date(b.data?.dateIntervention).getTime() - new Date(a.data?.dateIntervention).getTime()); 

                    console.log(this.appointments);
                   }
                
                  
                 })
              }
           }
          else if(status == 'previous')
           {
            if(data.status != 'pending')
            {
              // this.loader = false;
              this.subs = this.utilityService.getSingleUser(data.userId).subscribe(x => {
                let temp = this.appointments.filter(data => data.key == key)
                  if(temp.length == 0)
                   {
                    this.appointments.push({'key' : key,'data' : data,user : x})
                    this.appointments = this.appointments.sort((a,b) =>  new Date(b.data?.dateIntervention).getTime() - new Date(a.data?.dateIntervention).getTime()); 

                    console.log(this.appointments);
                   }

               })
            }
           }
           else if(status == 'all')
           {
            // this.loader = false;
              this.subs = this.utilityService.getSingleUser(data.userId).subscribe(x => {
                let temp = this.appointments.filter(data => data.key == key)
                  if(temp.length == 0)
                   {
                    this.appointments.push({'key' : key,'data' : data,user : x})
                    this.appointments = this.appointments.sort((a,b) =>  new Date(b.data?.dateIntervention).getTime() - new Date(a.data?.dateIntervention).getTime()); 

                    console.log(this.appointments);
                   }
                   
               })
           }      
       })
     })
    //  setTimeout(() => {
      this.appointments = this.appointments.sort((a,b) =>  new Date(b.data?.dateIntervention).getTime() - new Date(a.data?.dateIntervention).getTime()); 

    //  },1200);
   }
   selectedItem(key,data)
    {
      this.selectedKey = key;
      console.log("data",data?.dateIntervention);
      console.log("key",key);
      
      let intervention;
      let date = new Date(data?.dateIntervention).getDate();
      let month = new Date(data?.dateIntervention).getMonth();
      let year = new Date(data?.dateIntervention).getFullYear();
      let mstr = month.toString()
        if(mstr.length == 1)
         {
              intervention = year + "-" + 0 + month;
         }
        else {
              intervention = year + "-" + month
             }
            let dstr = date.toString();
       if(dstr.length == 1)
         {
           console.log("date length11",dstr.length);
             intervention = year + "-" +  0 + month + "-" + 0 + date;
         }
      else {
          console.log("date length",dstr);
              intervention = year + "-" +  0 + month + "-" + date;
          }
      this.updateAppointment.get('timingIntervention').setValue(data?.timingIntervention);
      this.updateAppointment.get('dateIntervention').setValue(intervention);
      this.updateAppointment.get('status').setValue('pending');
      this.updateAppointment.get('revisionDate').setValue(data?.revisionDate);
       if(data?.text)
        {
          this.problemDescription = data?.text;
          this.updateAppointment.get('text').setValue(data?.text)
        }
      this.selectedOrderId = data?.orderId;
    }
    updateAppointment = new FormGroup({
      timingIntervention : new FormControl(null,Validators.required),
      dateIntervention : new FormControl(null,Validators.required),
      status : new FormControl(null,Validators.required),
      comments : new FormControl(null),
      revisionDate : new FormControl(null,Validators.required),
      text : new FormControl(null)
    })
    onUpdate()
     {
       let temp;
       this.loader2 = true;  
       this.utilityService.updateAppointment(this.updateAppointment.value,this.selectedKey).then(()=> {
         if(this.updateAppointment.value.revisionDate != "null")
         {
           temp = +this.updateAppointment.value.revisionDate;
           let date = new Date(this.updateAppointment.value.dateIntervention).getDate();
           let month = new Date(this.updateAppointment.value.dateIntervention).getMonth() + 1;
           let year = new Date(this.updateAppointment.value.dateIntervention).getFullYear();
           year = new Date(this.updateAppointment.value.dateIntervention).getFullYear() + temp; 
           let revisionDate = new Date(year,month,date).toDateString();
           this.utilityService.updateOrder(this.selectedOrderId,revisionDate,this.updateAppointment.value.revisionDate).then(
             ()=> {
              this.loader2 = false;
              this.utilityService.showSuccess("Appointment","Appointment updated successfully");
             }).catch(err => {
              this.loader2 = false;
              this.utilityService.showError("Appointment",err.message)
             })        
         }
         else {
          this.loader2 = false;
          this.utilityService.showSuccess("Appointment","Appointment updated successfully");
         }
         
       }).catch(err => {
        this.loader2 = false;
         this.utilityService.showError("Appointment",err.message)
       })
     }
     details(orderId)
      {
        // this.appointmentsDetails = [];
        this.utilityService.getOrderDetails(orderId).subscribe(x => {
          this.appointmentsDetails.push({data :x,orderId : orderId }) ;
          console.log(this.appointmentsDetails);
        })
      }
      ngOnDestroy()
       {
         this.subs2.unsubscribe()
      }
      setFilteredItems()
       {
        this.filterResult =  [...this.appointments];
        this.filterResult =this.appointments.filter((item =>{
            console.log(item);   
        return item.user.firstName.toLowerCase().includes(this.search.toLowerCase()) 
         }))
       } 
}

