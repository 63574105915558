import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-previous-appointments',
  templateUrl: './previous-appointments.component.html',
  styleUrls: ['./previous-appointments.component.css']
})
export class PreviousAppointmentsComponent implements OnInit {

  appointments = [];
  subs : Subscription;
  selectedKey;
  selectedData;
  appointmentsDetails;
   constructor(private utilityService : UtilityService) { }
 
   ngOnInit(): void {
     this.getsAppointments();
   }   
   getsAppointments()
    {
     this.appointments = [];
      this.utilityService.getPreviousAppointments().subscribe(x => {
       this.appointments = [];
        x.forEach(el => {
           let data :any= el.payload.doc.data()
           let key = el.payload.doc.id
         this.subs = this.utilityService.getSingleUser(data.userId).subscribe(x => {
            this.appointments.push({'key' : key,'data' : data,user : x})
            console.log(this.appointments);
           //  this.subs.unsubscribe()
           })    
        })
      })
    }
    selectedItem(key,data)
     {
       this.selectedKey = key;
       console.log("data",data?.dateIntervention);
       console.log("key",key);
       this.updateAppointment.get('timingIntervention').setValue(data?.timingIntervention);
       this.updateAppointment.get('dateIntervention').setValue(data?.dateIntervention);
       this.updateAppointment.get('status').setValue('pending');
     }
     updateAppointment = new FormGroup({
       timingIntervention : new FormControl(null),
       dateIntervention : new FormControl(null),
       status : new FormControl(null),
       comments : new FormControl(null)
     })
     onUpdate()
      {
        this.utilityService.updateAppointment(this.updateAppointment.value,this.selectedKey).then(()=> {
          this.utilityService.showSuccess("Appointment","Appointment updated successfully")
        }).catch(err => {
          this.utilityService.showError("Appointment",err.message)
        })
      }
      details(orderId)
      {
        this.utilityService.getOrderDetails(orderId).subscribe(x => {
          this.appointmentsDetails = x;
          console.log(x);
        })
      }

}
