<div class="d-flex">
  <div>
    <app-sidebar></app-sidebar>
  </div>
  <div>
    <div class="mx-4 my-5">

      <div class="d-flex">

        <p class="apmnts" (click) = "getsAppointments('active')" [ngClass] = "{'active' : status == 'active'}">Programari Active</p>
        <p class="apmnts mx-3" (click) = "getsAppointments('previous')" [ngClass] = "{'active' : status == 'previous'}">Programari Anterioare</p>
        <p class="apmnts" (click) = "getsAppointments('all')" [ngClass] = "{'active' : status == 'all'}">Toate Programarile</p>
          <!-- <a class="nav-link" aria-current="page" routerLink = "/appointments" routerLinkActive = "active">Active Appointments</a>

          <a class="nav-link" routerLink = "/previous-appointments" routerLinkActive = "active">Previous Appointments</a>

          <a class="nav-link" routerLink = "/previous-appointments" routerLinkActive = "active"></a> -->


      </div>

      <!-- <button class="btn btn-outline-danger">Active Appointments</button>
      <button class="btn btn-outline-danger mx-3">Previous Appointments</button>
      <button class="btn btn-outline-danger">All Appointments</button>   -->
    </div>

    <sa-spinner-circular [enabled]="loader" size = "300px" class="w-75" color = "#C41230"></sa-spinner-circular>
    <div class="mt-5 mb-3 row justify-content-end">
      <input type="text" class="form-control me-5" id="exampleFormControlFile1" placeholder="Search" style="width: 250px;" [(ngModel)] = "search" (input) = "setFilteredItems()">
    </div>
    <table class="table table-striped table-bordered mt-5 mx-2 table-borderless">
      <thead style="border-bottom:1px solid red">
        <tr>
          <th scope="col">Nume</th>
          <th scope="col">Telefon</th>
          <th scope="col">Email</th>
          <th>Echipament</th>
          <th>Interventie service</th>
          <th scope="col">Interval interventie</th>
          <th scope="col">Data interventie </th>
          <th>Interventie prioritara</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody *ngIf = "search == ''">
        <tr *ngFor = "let apt of appointments">
          <td>{{apt?.user?.firstName }} {{apt?.user?.lastName}} </td>
          <td>{{apt?.user?.phone}}</td>
          <td>{{apt?.user?.email}}</td>
          <td>{{apt?.data?.equipmentType}} <br>
              {{apt?.data?.brand}} <br>
              {{apt?.data?.model}} <br>
              SN : {{apt?.data?.serialNumber}}
          </td>
          <td>{{apt?.data?.service}}</td>
          <td>{{apt?.data?.timingIntervention}}</td>
          <td>{{apt?.data?.dateIntervention | date : "dd-MM-YYYY"}}</td>
          <td>{{apt?.data?.priorityIntervention}}</td>
          <td *ngIf = "apt?.data?.status == 'pending'">in asteptarea</td>
          <td *ngIf = "apt?.data?.status == 'inProgress'">In lucru</td>
          <td *ngIf = "apt?.data?.status == 'Complete'">Finalizata</td>
          <td>
              <img src = "assets/edit.png" data-bs-toggle="modal" data-bs-target="#exampleModal" (click) = "selectedItem(apt?.key,apt?.data)">
          </td>
        </tr>

      </tbody>

      <tbody *ngIf = "search != ''">
        <tr *ngFor = "let apt of filterResult">
          <td>{{apt?.user?.firstName }} {{apt?.user?.lastName}} </td>
          <td>{{apt?.user?.phone}}</td>
          <td>{{apt?.user?.email}}</td>
          <td>{{apt?.data?.equipmentType}} <br>
              {{apt?.data?.brand}} <br>
              {{apt?.data?.model}} <br>
              SN : {{apt?.data?.serialNumber}}
          </td>
          <td>{{apt?.data?.service}}</td>
          <td>{{apt?.data?.timingIntervention}}</td>
          <td>{{apt?.data?.dateIntervention | date : "dd-MM-YYYY"}}</td>
          <td>{{apt?.data?.priorityIntervention}}</td>
          <td *ngIf = "apt?.data?.status == 'pending'">in asteptarea</td>
          <td *ngIf = "apt?.data?.status == 'inProgress'">In lucru</td>
          <td *ngIf = "apt?.data?.status == 'Complete'">Finalizata</td>
          <td>
              <img src = "assets/edit.png" data-bs-toggle="modal" data-bs-target="#exampleModal" (click) = "selectedItem(apt?.key,apt?.data)">
          </td>
        </tr>

      </tbody>
    </table>
    <sa-spinner-circular [enabled]="loader2" size = "100px" style="float: right;" color = "#C41230" ></sa-spinner-circular>

    <div class="modal fade exampleModal-modal-lg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Appointment</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form [formGroup] = "updateAppointment" (ngSubmit) = "onUpdate()">
              <div class="mb-2">
                <label for="recipient-name" class="col-form-label">Interval interventie:</label>
                <select class="form-select" aria-label="Default select example" formControlName = "timingIntervention">
                    <option value = "09:00 - 15:00">09:00 - 15:00</option>
                    <option value = "15:00 - 17:00">15:00 - 17:00</option>
                    <option value = "Dupa 17:00">Dupa 17:00</option>
                </select>
              </div>
              <div class="mb-2">
                <label for="recipient-name" class="col-form-label">Data interventie:</label>
                <input type="date" class="form-control" id="message-text" formControlName = "dateIntervention" min="2022-03-28">
              </div>
              <div class="mb-2">
                <label for="recipient-name" class="col-form-label">Status</label>
                <select class="form-select" aria-label="Default select example" formControlName = "status">
                  <option value = "inProgress">In lucru</option>
                  <option value = "pending">In asteptare</option>
                  <option value = "Complete">Finalizata</option>
              </select>
              </div>
              <div class="mb-2">
                <label for="message-text" class="col-form-label me-3">Data urmatoarei revizii</label> <br>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="inlineRadio1" value=1 formControlName = "revisionDate">
                  <label class="form-check-label" for="inlineRadio1">1 an</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="inlineRadio2" value=2 formControlName = "revisionDate">
                  <label class="form-check-label" for="inlineRadio2">2 ani</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="inlineRadio2" value=null formControlName = "revisionDate">
                  <label class="form-check-label" for="inlineRadio2">none</label>
                </div>
              </div>
              <div class="mb-2" *ngIf = "problemDescription">
                <label for="recipient-name" class="col-form-label">Problem Description:</label>
                <textarea  class="form-control" id="message-text" formControlName = "text" readonly></textarea>
              </div>
              <div class="mb-2">
                <label for="recipient-name" class="col-form-label">Comentariu:</label>
                <textarea  class="form-control" id="message-text" formControlName = "comments"></textarea>
              </div>
              <button type="submit" class="btn btn-danger w-100" data-bs-dismiss="modal" [disabled] = "!updateAppointment.valid">Actualizează</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="modal fade exampleModal-modal-lg" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Appointment Details</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Type: <span class="text-danger">{{appointmentsDetails?.equipmentType}} </span></p>
            <p>Brand: <span class="text-danger">{{appointmentsDetails?.equipmentBrand}} </span></p>
            <p>Brand: <span class="text-danger">{{appointmentsDetails?.equipmentModel}} </span></p>
            <p>Purchase date : <span class="text-danger">{{appointmentsDetails?.purchaseDate}} </span></p>
            <p>Commercial Warranty: <span class="text-danger">{{appointmentsDetails?.commercialWarrantyDate | date : "yyyy-MM-dd" }} </span></p>
            <p>Extended Warranty: <span class="text-danger">{{appointmentsDetails?.extendedWarrantyDate | date : "yyyy-MM-dd"}} </span></p>
            <p>Contract Number: <span class="text-danger">{{appointmentsDetails?.contractNumber}} </span></p>
            <p>Contract Type: <span class="text-danger">{{appointmentsDetails?.contractType}} </span></p>
            <p>payment Period: <span class="text-danger">{{appointmentsDetails?.paymentPeriod}} </span></p>
            <p>Serial Number: <span class="text-danger">{{appointmentsDetails?.serialNumber}}</span></p>
            <p *ngIf = "appointmentsDetails?.plan != null"> <span class="text-danger">  Subscribe Plan: {{appointmentsDetails?.plan}}</span> </p>
            <p *ngIf = "appointmentsDetails?.status != null"> <span class="text-danger"> Subscription Status: {{appointmentsDetails?.status}}</span></p>
            <p *ngIf = "appointmentsDetails?.date != null"> <span class="text-danger">Renewal Date: {{appointmentsDetails?.date}}</span> </p>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</div>
