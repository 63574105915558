import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  users;
  loader = false;
  search = "";
  filterResult
  constructor(private utilityService : UtilityService,
              private router : Router) { }

  ngOnInit(): void {
    this.getCustomers()
  }
  getCustomers()
   {
     this.loader = true;
     this.utilityService.getUsers().subscribe(x=> {
       this.loader = false;
       this.users = x;
       console.log(x);
     })
    //  this.users = this.users.reverse();
   }
   gotodetails(userId)
    {
     this.router.navigateByUrl('/customers/details/' + userId);
    }
 
   
    setFilteredItems()
     {
       
    this.filterResult =  [...this.users];
    this.filterResult =this.users.filter((item =>{
        console.log(item);   
    return item.firstName.toLowerCase().includes(this.search.toLowerCase()) 
     }))
    }

}
