import { AfterContentInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from '../utility.service';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit,AfterContentInit {
  types = [];
  equipments = [];
  brands = [];
  models = [];
  id;
  user;
  orders = [];
  selectedType;
  show = false;
  picture = 'assets/user.svg';
  selectedKey;
  selecteddata;
  appointments = [];
  subDetails;
  nextDate = null;
  status = null;
  plan = null;
  date = new Date().getDate();
  month = new Date().getMonth();
  year = new Date().getFullYear();
  max;
  other = false;
  orderKey;
  orderData;
  selectedServiceId;
  loader = false;
  notificationLoader = false;
  editEquipLoader = false;
  deleteEquipLoader = false;
  editServiceLoader = false;
  deleteServiceLoader = false;
  deleteSubLoader = false;
  selectedOrderId;
  showKey = null;
  emailLoader = false;
  constructor(private utilityService : UtilityService,
              private route : ActivatedRoute) {
              }

  ngOnInit(): void {
    if(this.date < 10 && this.month < 10)
    {
     this.max = this.year + "-" +  0 + this.month + "-" + 0 + this.date;
    }
   else if(this.date < 10 && this.month > 10)
    {
     this.max = this.year + "-" + this.month + "-" + 0 + this.date;
    }
    else if(this.date >= 10 && this.month < 10)
    {
     this.max = this.year + "-" + 0 + this.month + "-" + this.date;
    }
    else {
     this.max = this.year + "-" + this.month + "-" + this.date;
    }
    console.log("maxx",this.max);
  }
 ngAfterContentInit()
  {
    this.route.paramMap.subscribe((id : any)=> {
      this.id = id.params.id;
      console.log("id",this.id);
    })
    this.getEquipments();
    this.getUser(this.id);
    this.getOrders(this.id);
    this.getAppointment(this.id)
  }
  getUser(id)
   {
     this.utilityService.getSingleUser(id).subscribe(x => {
       this.user = x;
       if(this.user?.picture)
        {
          this.picture = this.user?.picture;
        }

       console.log("user",this.user);
     })
   }
  getEquipments()
   {
     this.equipments = null;
     this.types = [];
    this.utilityService.getEquipments().subscribe(x => {
       this.types = [];
       this.equipments = null;
      this.equipments = x;
      x.forEach((el :any) =>{
        this.types.push(el.type)
      })
      console.log("types",this.types);
    })
   }
   getOrders(id)
    {
      this.orders = []
      this.utilityService.getOrders(id).snapshotChanges().subscribe(x=> {
        this.orders = [];
        x.forEach((el => {
         let data :any = el.payload.doc.data();
         console.log("data",data);
         let date;let nextDate; let status;let plan;
         console.log("data :", el.payload.doc, "Id",el.payload.doc.id);
          if(data.subscription_id)
          {
           this.utilityService.getSubscriptionDetails(data?.subscription_id).subscribe((x : any)=> {
            console.log("we are here222");
            this.subDetails = x;
              let date = x.invoice.next_payment_attempt * 1000;
               this.nextDate = new Date(date).toDateString();
               this.status = x.subscription.status;
               this.plan = x.invoice.lines.data[0].description;
             console.log("usernn",this.subDetails);
             let existingOrders : any= this.orders.filter(od=> od.key == el.payload.doc.id);
             if(existingOrders.length == [])
              {
                this.orders.push({'data' : el.payload.doc.data(),'key' : el.payload.doc.id,'date' : this.nextDate,'status' : this.status,'plan' : this.plan})
              }
            console.log("orders",this.orders);
          }),(err) => {
            console.log("facing error",err);
          }}
          else {
            this.orders.push({'data' : el.payload.doc.data(),'key' : el.payload.doc.id,'date' : null,'status' : null,'plan' : null})
          }
        }))
      })
  }
  getAppointment(id)
  {
    this.utilityService.getAppointmentByUser(id).subscribe(x => {
      this.appointments = [];
      x.forEach(y => {
        this.appointments.push({data : y.payload.doc.data(), id : y.payload.doc.id})
      })
    })
  }
  addNewEquipment = new FormGroup({
    equipmentType : new FormControl(null,Validators.required),
    equipmentBrand : new FormControl(null,Validators.required),
    equipmentModel : new FormControl(null,Validators.required),
    purchaseDate :  new FormControl(null,Validators.required),
    commercialWarranty : new FormControl(null,Validators.required),
    extendedWarranty : new FormControl(null),
    contractNumber : new FormControl(null),
    contractType : new FormControl(null),
    paymentPeriod : new FormControl(null),
    serialNumber : new FormControl(null,Validators.required),
    extraWarranty : new FormControl(null),
    address : new FormControl(null,Validators.required)
    // revisionDate : new FormControl(null,Validators.required),

  })
  onSubmit()
   {
    this.loader = true;
    let year
     let temp = +this.addNewEquipment.value.commercialWarranty;
     let date = new Date(this.addNewEquipment.value.purchaseDate).getDate();
     let month = new Date(this.addNewEquipment.value.purchaseDate).getMonth();
      if(this.addNewEquipment.value.extraWarranty != null)
       {
        year = new Date(this.addNewEquipment.value.purchaseDate).getFullYear() + (temp + 2);
       }
      else {
        year = new Date(this.addNewEquipment.value.purchaseDate).getFullYear() + temp;
      }

     let commercialWarranty = new Date(year,month,date).toDateString();

     let temp3 = +this.addNewEquipment.value.extendedWarranty;
     console.log("temp3",temp3);
     let extendedWarranty = null
     if(temp3 != 0)
      {
        let date2 = new Date(commercialWarranty).getDate();
        let month2 = new Date(commercialWarranty).getMonth();
        let year2 = new Date(commercialWarranty).getFullYear() + temp3;
        extendedWarranty = new Date(year2,month2,date2).toDateString();
      }

     console.log("commercialWarranty",commercialWarranty);
     console.log("extended Warranty",extendedWarranty);
    let model =  this.models[+this.addNewEquipment.value.equipmentModel];
     this.addNewEquipment.get('equipmentModel').setValue(model.name);
     this.utilityService.addEquipment(this.addNewEquipment.value,commercialWarranty,extendedWarranty,this.id,model.image_url).then(()=> {
      this.loader = false;
      this.utilityService.showSuccess("Equipment","Equipment added successfully");
        this.addNewEquipment.reset();
        this.brands = null;
        this.models = null;
     }).catch(err => {
      this.loader = false;
      this.utilityService.showError("Equipment",err.message);
     })
   }
   onSelectType(val)
    {
      this.brands = null;
      this.models = null;

      if(val == 'edit')
       {
        this.show = false;
        this.selectedType = this.editNewEquipment.value.equipmentType
        let temp  : any= this.equipments.filter(el => el.type == this.editNewEquipment.value.equipmentType);
        this.brands = temp[0].brand;
        this.editNewEquipment.get('equipmentBrand').setValue(this.selecteddata.equipmentBrand);
        this.onSelectBrand('edit');
        console.log("selected Type",this.selectedType);
        if(this.selectedType == 'POMPE DE CALDURA' || this.selectedType == 'CENTRALA TERMICA')
         {
             this.show = true
         }
       }
      else if(val == 'new')
       {
        this.show = false;
        this.selectedType = this.addNewEquipment.value.equipmentType;
        let temp  : any= this.equipments.filter(el => el.type == this.addNewEquipment.value.equipmentType);
        this.brands = temp[0].brand;
        if(this.selectedType == 'POMPE DE CALDURA' || this.selectedType == 'CENTRALA TERMICA')
         {
             this.show = true
         }
         console.log("show",this.show);
         this.onSelectBrand('new');
       }
      //  this.onSelectBrand('new');
    }
    onSelectBrand(val)
     {
       this.models = null;
      //  console.log("eqBrand",this.selecteddata.equipmentModel);
      if(val == 'new')
       {
        let temp  : any= this.brands.filter(el => el.name == this.addNewEquipment.value.equipmentBrand);
        console.log("temp",temp)
        this.models = temp[0]?.model
       }
      else if('edit')
       {
        this.editNewEquipment.get('equipmentModel').setValue(this.selecteddata.equipmentModel);
        console.log("mm",this.editNewEquipment.value.equipmentModel);

        console.log("eqBrand",this.selecteddata.equipmentModel);
        let temp  : any= this.brands.filter(el => el.name == this.editNewEquipment.value.equipmentBrand);
        console.log("temp",temp)  
        this.models = temp[0]?.model;
        console.log("models",this.models);
        console.log("mm",this.editNewEquipment.value.equipmentModel);
        
        
       }
     }
     notification = new FormGroup({
      title : new FormControl(null,Validators.required),
      message : new FormControl(null,Validators.required),
     })
     onNotification()
      {
       this.notificationLoader = true;
       this.utilityService.sendNotification(this.notification.value,this.user.token)
        .then(()=> {
          this.notificationLoader = false;
          this.notification.reset();
          this.utilityService.showSuccess("Notification","Notification sent successfully");
        }).catch(err => {
          this.notificationLoader = false;
          this.utilityService.showError("Notification","Something went wrong!!");
        })
      }

      editNewEquipment = new FormGroup({
        equipmentType : new FormControl(null,Validators.required),
        equipmentBrand : new FormControl(null,Validators.required),
        equipmentModel : new FormControl(null,Validators.required),
        purchaseDate :  new FormControl(null,Validators.required),
        commercialWarranty : new FormControl(null,Validators.required),
        extendedWarranty : new FormControl(null),
        contractNumber : new FormControl(null),
        contractType : new FormControl(null),
        paymentPeriod : new FormControl(null),
        serialNumber : new FormControl(null,Validators.required),
        extraWarranty : new FormControl(null),
        address : new FormControl(null,Validators.required)

      })
   selectedItem(key,data)
    {
     this.selectedKey = key;
     this.selecteddata = data;
     console.log("data",this.selecteddata);
     console.log("serial ",this.selecteddata.serialNumber);
     this.editNewEquipment.get('equipmentType').setValue(this.selecteddata.equipmentType);

     this.editNewEquipment.get('purchaseDate').setValue(this.selecteddata.purchaseDate);
     this.editNewEquipment.get('commercialWarranty').setValue(this.selecteddata.commercialWarranty);
     this.editNewEquipment.get('extendedWarranty').setValue(this.selecteddata.extendedWarranty);
   //  this.editNewEquipment.get('revisionDate').setValue(this.selecteddata.revisionDate);
     this.editNewEquipment.get('serialNumber').setValue(this.selecteddata.serialNumber);
     this.editNewEquipment.get('address').setValue(this.selecteddata.address)
     this.onSelectType('edit');
     this.editNewEquipment.get('equipmentBrand').setValue(this.selecteddata.equipmentBrand);
     console.log("eqBrand",this.selecteddata.equipmentBrand);
    this.onSelectBrand('edit');
    console.log("mmmmodal",this.selecteddata.equipmentModel);
    // setTimeout(() => {
    //   this.editNewEquipment.get('equipmentModel').setValue(this.selecteddata.equipmentModel)
    //   console.log("mm",this.editNewEquipment.value.equipmentModel);
    // },1000);

 

   // this.onSelectType('edit');

      if(this.selecteddata.contractNumber)
       {
         console.log("we are here");
        this.editNewEquipment.get('contractNumber').setValue(this.selecteddata.contractNumber);
       }
       else {
        this.editNewEquipment.get('contractNumber').setValue(null);
       }
       if(this.selecteddata.contractType)
       {
        this.editNewEquipment.get('contractType').setValue(this.selecteddata.contractType);
       }
       else {
        this.editNewEquipment.get('contractType').setValue(null);
       }
       if(this.selecteddata.paymentPeriod)
       {
        this.editNewEquipment.get('paymentPeriod').setValue(this.selecteddata.paymentPeriod);
       }
       else {
        this.editNewEquipment.get('paymentPeriod').setValue(null);
       }
       if(this.selecteddata.extraWarranty)
        {
          this.editNewEquipment.get('extraWarranty').setValue(this.selecteddata.extraWarranty);
        }
       else {
        this.editNewEquipment.get('extraWarranty').setValue(null);
       } 
    }
    onUpdate()
     {
      let year
      this.editEquipLoader = true;
      let temp = +this.editNewEquipment.value.commercialWarranty;
      let date = new Date(this.editNewEquipment.value.purchaseDate).getDate();
      let month = new Date(this.editNewEquipment.value.purchaseDate).getMonth();
      if(this.editNewEquipment.value.extraWarranty != null)
       {
        year = new Date(this.editNewEquipment.value.purchaseDate).getFullYear() + temp + 2;
       }
      else {
        year = new Date(this.editNewEquipment.value.purchaseDate).getFullYear() + temp;
      }
      let commercialWarranty = new Date(year,month,date).toDateString();
      let temp3 = +this.editNewEquipment.value.extendedWarranty;
      let extendedWarranty = null;
      if(temp3 != 0)
       {
        let date2 = new Date(commercialWarranty).getDate();
        let month2 = new Date(commercialWarranty).getMonth();
        let year2 = new Date(commercialWarranty).getFullYear() + temp3;
        extendedWarranty = new Date(year2,month2,date2).toDateString();
       }

      console.log("commercialWarranty",commercialWarranty);
      console.log("extended Warranty",extendedWarranty);
     let model : any= this.models.filter(el=> el.name == this.editNewEquipment.value.equipmentModel);
      this.utilityService.updateEquipment(this.editNewEquipment.value,this.selectedKey,commercialWarranty,extendedWarranty,model[0]?.image_url).then(()=> {
        this.editEquipLoader = false;
        this.utilityService.showSuccess("Equipment","Equipment updated successfully");
         this.addNewEquipment.reset();
       }).catch(err => {
        this.editEquipLoader = false;
         this.utilityService.showError("Equipment",err.message);
     })
     }
    onDelete(key)
     {
      this.deleteEquipLoader = true;
       this.utilityService.deleteEquipment(key).then(()=>{
        this.deleteEquipLoader = false;
        this.utilityService.showSuccess("Equipment","Equipment deleted successfully");
       }).catch(err => {
        this.deleteEquipLoader = false;
         this.utilityService.showError("Equipment",err.message);
       })
     }
    addNew()
     {
       this.addNewEquipment.reset();
       this.brands = null;
       this.models = null;
     }
     addService(key,userId)
      {
        this.orderKey = key;
        this.orderData = userId
         console.log("key",key);

         console.log("userId",userId);
      }
      addServiceForm = new FormGroup({
        dateIntervention : new FormControl(null,Validators.required),
        service : new FormControl(null,Validators.required),
        comments : new FormControl(""),
        revisionDate : new FormControl(null,Validators.required),
        timingIntervention : new FormControl(null,Validators.required),
      })
      editServiceForm = new FormGroup({
        dateIntervention : new FormControl(null,Validators.required),
        service : new FormControl(null,Validators.required),
        comments : new FormControl(""),
        timingIntervention : new FormControl(null,Validators.required),
        revisionDate : new FormControl(null,Validators.required),
      })
      toggleRadio(val)
       {
         if(val == true)
           {
             this.other = true;
             this.addServiceForm.get('service').setValue(null);
           }
           else {
             this.other = false;
           }
       }
       submitServiceForm()
        {
          let temp;
              let revisionDate = "null";
              if(this.addServiceForm.value.revisionDate != "null")
              {
                temp = +this.addServiceForm.value.revisionDate;
                let date = new Date(this.addServiceForm.value.dateIntervention).getDate();
                let month = new Date(this.addServiceForm.value.dateIntervention).getMonth();
                let year = new Date(this.addServiceForm.value.dateIntervention).getFullYear();
                year = new Date(this.addServiceForm.value.dateIntervention).getFullYear() + temp;
                revisionDate = new Date(year,month,date).toDateString();
              }
           this.utilityService.submitAppointment(this.addServiceForm.value,this.orderKey,this.orderData)
            .then(()=> {
              if(this.editServiceForm.value.revisionDate != "null")
              {
              this.utilityService.updateOrder(this.orderKey,revisionDate,this.addServiceForm.value.revisionDate).then(()=> {
                this.utilityService.showSuccess("Service","Service Added Successfully");
                this.addServiceForm.reset();
              }).catch(err => {
                this.utilityService.showError("Service",err.message);
              })
            }
            else {
              this.utilityService.showSuccess("Service","Service Added Successfully");
            }
            }).catch(err => {
              this.utilityService.showError("Service",err.message);
            })

          }
          deleteService(id)
           {
            this.selectedServiceId = id;
           }
          onDeleteService()
           {
             this.utilityService.deleteServiceById(this.selectedServiceId).then
             (() => {
               this.utilityService.showSuccess("Delete Service","Service deleted successfully");
             }).catch(err => {
              this.utilityService.showSuccess("Delete Service",err.message);
             })
           }
           editService(id,data)
            {
              let intervention;
              this.selectedServiceId = id;
              this.selectedOrderId = data?.orderId;
              let date = new Date(data?.dateIntervention).getDate();
              let month = new Date(data?.dateIntervention).getMonth() + 1;
              let year = new Date(data?.dateIntervention).getFullYear();
              let mstr = month.toString()
                if(mstr.length == 1)
                 {
                      intervention = year + "-" + 0 + month;
                 }
                else {
                      intervention = year + "-" + month
                     }
                    let dstr = date.toString();
               if(dstr.length == 1)
                 {
                   console.log("date length11",dstr.length);
                     intervention = year + "-" +  0 + month + "-" + 0 + date;
                 }
              else {
                  console.log("date length",dstr);
                      intervention = year + "-" +  0 + month + "-" + date;
                  }
               console.log("intervention",intervention);
              this.editServiceForm.get('dateIntervention').setValue(intervention),
              this.editServiceForm.get('service').setValue(data?.service),
              this.editServiceForm.get('comments').setValue(data?.comments);
              this.editServiceForm.get('revisionDate').setValue(data?.revisionDate);
              console.log("revision Date",data?.revisionDate," -","type", typeof(data?.revisionDate));
              if(data?.timingIntervention)
               {
                this.editServiceForm.get('timingIntervention').setValue(data?.timingIntervention);
               }
            }
           onEditService()
            {
              let temp;
              let revisionDate = "null";
              if(this.editServiceForm.value.revisionDate != "null")
              {
                temp = +this.editServiceForm.value.revisionDate;
                let date = new Date(this.editServiceForm.value.dateIntervention).getDate();
                let month = new Date(this.editServiceForm.value.dateIntervention).getMonth();
                let year = new Date(this.editServiceForm.value.dateIntervention).getFullYear();
                year = new Date(this.editServiceForm.value.dateIntervention).getFullYear() + temp;
                revisionDate = new Date(year,month,date).toDateString();
              }
              this.utilityService.editService(this.editServiceForm.value,this.selectedServiceId).then(
              ()=> {
                if(this.editServiceForm.value.revisionDate != "null")
                 {
                  this.utilityService.updateOrder(this.selectedOrderId,revisionDate,this.editServiceForm.value.revisionDate).then(()=> {
                    this.utilityService.showSuccess("Service","Service updated successfully");
                   }).catch(err => {
                    this.utilityService.showSuccess("Service",err.message);
                   })
                 }
                else {
                  this.utilityService.showSuccess("Service","Service updated successfully");
                }  
              }).catch(err => {
                this.utilityService.showSuccess("Service",err.message);
              })

            }
            showService(key)
             {
               if(key == this.showKey)
                {
                  this.showKey = null
                }
                else {
                  this.showKey = key;
                }

             }
             sendDiscount()
              {
                this.emailLoader = true;
                  let code : string = uuidv4();
                 code = code.substring(0,6)
                 this.utilityService.addDiscount(this.id,code).then(()=> {
                   this.utilityService.sendEmail(this.user?.firstName,code,this.user?.email).subscribe(x => {
                    this.emailLoader = false;
                     this.utilityService.showSuccess("Voucher","Voucher has been sent succesfully");
                   })
                   console.log("code added")
                 }).catch(err => {
                   this.emailLoader = false;
                   console.log("something went wrong!");
                 })
              }

    deleteSub(key,subscription_id)
     {
       this.deleteSubLoader = true;
       console.log("sub",subscription_id);
       this.utilityService.deleteSubscriptin(key,subscription_id).subscribe((x :any)=> {
         if(x.msg == 'deleted successfully')
          {
             this.utilityService.updateSubscription(key).then(()=> {
                 this.deleteSubLoader = false;
                 this.utilityService.showSuccess("Delete Subscription","Subscription has been deleted successfully");
                 console.log("updated successfully");
             }).catch(err => {
              this.deleteSubLoader = false;
              this.utilityService.showSuccess("Delete Subscription",err.message);
               console.log("err",err);
             })
          } 
        
       })
     }       
}
